.footer {
  margin: 50px 170px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.footer-top {
  display: flex;
  justify-content: space-between;
}
.footer-top-left p {
  font-size: 20px;
  max-width: 400px;
}
.footer-top-right {
  display: flex;
  align-items: center;
  gap: 35px;
}
.footer-email-input {
  display: flex;
  gap: 30px;
  padding: 20px 30px;
  border-radius: 50px;
  background: #32323b;
  padding-right: 80px;
}
.footer-email-input:hover {
  border: 2px solid white;
}
.footer-email-input input {
  outline: none;
  border: none;
  background: transparent;
  color: #a0a0a0;
  font-family: Outline;
  font-size: 18px;
}
.footer-subscribe {
  font-size: 20px;
  padding: 22px 50px;
  border-radius: 50px;
  background: linear-gradient(264deg, #df8908 -5.09%, #b415ff 106.28%);
  cursor: pointer;
  transition: 0.3s;
}
.footer-subscribe:hover {
  transform: scale(1.1);
  transition: 0.3s;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}
.footer-bottom-right {
  display: flex;
  gap: 50px;
  margin-bottom: 50px;
}
.reset-a,
.reset-a:hover,
.reset-a:visited,
.reset-a:focus,
.reset-a:active {
  text-decoration: none;
  color: inherit;
  outline: 0;
  cursor: auto;
}
