* {
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  background: #161513;
  color: white;
  font-family: "outfit";
}
