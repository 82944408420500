.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 80px;
  margin: 80px 170px;
}
.about-title {
  position: relative;
}
.about-title h1 {
  padding: 0px 30px;
  font-size: 80px;
  font-weight: 600;
}
.about-title img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.about-sections {
  display: flex;
  gap: 80px;
}
.about-left {
  display: inline-block;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}
.about-left img {
  width: 100%;
  height: auto;
  margin-left: 0px;
  margin-top: 0px;
}
.about-right {
  display: flex;
  flex-direction: column;
  gap: 80px;
}
.about-para {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 24px;
  font-weight: 500;
}
.about-skills {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.about-skill {
  display: flex;
  gap: 50px;
  align-items: center;
  transition: 0.3s;
}
.about-skill:hover {
  transform: scale(1.05);
  transition: 0.3s;
}
.about-skill p {
  min-width: 150px;
  font-size: 24px;
  font-weight: 500;
}
.about-skill hr {
  outline: none;
  border: none;
  width: 50%;
  height: 8px;
  border-radius: 50px;
  background: linear-gradient(264deg, #df8908 -5.09%, #b415ff 106.28%);
}
.about-achievements {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 80px;
}
.about-achievement {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  transition: 0.5s;
}
.about-achievement:hover {
  transform: scale(1.12);
  transition: 0.5s;
}
.about-achievement h1 {
  font-size: 60px;
  font-weight: 700;
  background: linear-gradient(270deg, #df8908 50.41%, #b415ff 90.25%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-achievement p {
  font-size: 22px;
  font-weight: 500;
}
.social-icon {
  color: linear-gradient(270deg, #df8908 50.41%, #b415ff 90.25%);
}
