.hero {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 35px;
}
/* .hero img {
  margin-top: 150px;
  display: inline-block;
  width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 50%;
} */
.profile-container {
  display: inline-block;
  position: relative;
  margin-top: 125px;
  width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 50%;
}
.profile-container img {
  width: auto;
  height: 150%;
  margin-left: -40px;
  margin-top: -10px;
}
/* .profile-container {
  display: inline-block;
  position: relative;
  margin-top: 150px;
  width: 400px;
  height: 400px;
  overflow: hidden;
  border-radius: 50%;
  scale: 1;
}
.profile-container img {
  width: auto;
  height: 125%;
  margin-left: -175px;
  margin-top: -75px;
} */

.hero h1 {
  text-align: center;
  width: 70%;
  font-size: 90px;
  font-weight: 600;
}
.hero h1 span {
  background: linear-gradient(270deg, #df8908 10%, #b415ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.hero p {
  width: 50%;
  text-align: center;
  font-size: 24px;
  line-height: 40px;
}
.hero-action {
  display: flex;
  align-items: center;
  gap: 25px;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 50px;
}
.hero-connect {
  padding: 25px 45px;
  border-radius: 50px;
  background: linear-gradient(264deg, #df8908 -5.09%, #b415ff 106.28%);
  cursor: pointer;
}
.hero-connect:hover {
  border: 2px solid white;
}
.hero-resume {
  padding: 25px 75px;
  border-radius: 50px;
  border: 2px solid #fff;
  cursor: pointer;
}
.hero-resume:hover {
  border-color: #b415ff;
}
.reset-a,
.reset-a:hover,
.reset-a:visited,
.reset-a:focus,
.reset-a:active {
  text-decoration: none;
  color: inherit;
  outline: 0;
  cursor: auto;
}
